import React from "react";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";

const Awards = () => {
  return (
    <Layout>
      <Metadata
        title="Awards"
        description="Each year Hope Labs will shortlist the organizations that have done the most for the community."
      />
      <div>
        <h1>Awards Page</h1>
        <h2>This is a webpage</h2>
      </div>
    </Layout>
  );
};

export default Awards;
